
import { Vue, Component, Prop } from 'vue-property-decorator';
import qs from 'qs';
import { Invoice } from '@/interface/interface';
import InvoiceConfirm from '@/pages/project/billing/invoice-confirm.vue';

@Component({
  components: {
    InvoiceConfirm
  }
})
export default class BillingSpecific extends Vue {
  @Prop() surveyIds!: string[];
  private invoiceList: Invoice[] = [];
  private total = 0;
  private billingAddress: string | null = null;
  private billingContactEmail: string | null = null;
  private userGroup: string | null = null;
  private userGroupFullName: string | null = null;
  private terminateFee = 0;
  private quotaFullFee = 0;
  private digit = '';
  private currentIndex = 0;
  private invoiceConfirm = false;
  private invoiceShow = false;
  private invoiceId: string | null = null;
  private currentDate: Date = new Date();
  private depositDeadLine: Date = new Date();

  async created(): Promise<void> {
    if (!Array.isArray(this.surveyIds)) {
      await this.$router.push({ path: `/project/billing` });
    } else {
      if (this.surveyIds && this.surveyIds.length) await this.load();
    }
  }

  async goFirst(): Promise<void> {
    this.currentIndex = 0;
    this.invoiceConfirm = false;
    await this.goRef();
  }

  async load(): Promise<void> {
    this.loading = true;
    const url = `/billing/info`;
    const { data, result } = await this.axios({
      url,
      params: {
        surveyId: this.surveyIds
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });
    if (result) {
      const {
        list,
        total,
        billingAddress,
        billingContactEmail,
        userGroup,
        userGroupFullName,
        terminateFee,
        quotaFullFee,
        digit,
        depositDeadLine,
        currentDate,
        invoiceId,
      } = data as {
        list: Invoice[], total: number, billingAddress: string, billingContactEmail: string, userGroup: string, userGroupFullName: string, terminateFee: number; quotaFullFee: number; digit: string; depositDeadLine: string; currentDate: string
        invoiceId: string;
      };
      this.invoiceList = list;
      this.total = total;
      this.billingAddress = billingAddress;
      this.billingContactEmail = billingContactEmail;
      this.userGroup = userGroup;
      this.userGroupFullName = userGroupFullName;
      this.terminateFee = terminateFee;
      this.quotaFullFee = quotaFullFee;
      this.digit = digit;
      this.currentDate = new Date(currentDate);
      this.depositDeadLine = new Date(depositDeadLine);
      this.invoiceId = invoiceId;
    }
    this.loading = false;
  }

  async save(): Promise<void> {
    const ref = this.$refs.invoice as HTMLDivElement[];
    if (!ref) return;
    if (this.currentIndex < this.invoiceList.length - 1) {
      this.currentIndex += 1;
      await this.goRef();
      return;
    }
    this.invoiceConfirm = true;
  }

  async showInvoice(): Promise<void> {
    this.invoiceShow = true;
    // await this.$router.push({ path: `/project/billing/invoice-confirm`, query: { surveyId: this.invoices } });
  }

  close(): void {
    this.invoiceShow = false;
  }

  async goRef(): Promise<void> {
    await this.$nextTick();
    const ref = this.$refs.invoice as HTMLDivElement[];
    if (!ref) return;
    const currentRef = ref[this.currentIndex];
    if (currentRef) currentRef.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
  }
}
